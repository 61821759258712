import ANDROID from '../../static/images/logo-android.svg';
import IOS from '../../static/images/logo-ios.svg';
import JAVA from '../../static/images/logo-java.svg';
import JAVASCRIPT from '../../static/images/logo-javascript.svg';
import NODEJS from '../../static/images/logo-nodejs.svg';
import PYTHON from '../../static/images/logo-python.svg';
import GOLANG from '../../static/images/logo-go.svg';
import REACT from '../../static/images/logo-react.svg';

export const SDKItems = [
  {
    title: 'Android',
    image: ANDROID,
    description: 'Implementation of the Skyflow SDK for Android applications',
    link: 'https://github.com/skyflowapi/skyflow-android-sdk',
  },
  {
    title: 'iOS',
    image: IOS,
    description: 'Implementation of the Skyflow SDK for iOS applications',
    link: 'https://github.com/skyflowapi/skyflow-iOS',
  },
  {
    title: 'JavaScript',
    image: JAVASCRIPT,
    description:
      'Implementation of the Skyflow SDK for Javascript applications',
    link: 'https://github.com/skyflowapi/skyflow-js',
  },
  {
    title: 'React',
    image: REACT,
    description:
      'Implementation of the Skyflow SDK for React applications',
    link: 'https://github.com/skyflowapi/skyflow-react-js',
  },
  {
    title: 'Java',
    image: JAVA,
    description: 'Implementation of the Skyflow SDK for Java applications',
    link: 'https://github.com/skyflowapi/skyflow-java',
  },
  {
    title: 'NodeJS',
    image: NODEJS,
    description: 'Implementation of the Skyflow SDK for NodeJS applications',
    link: 'https://github.com/skyflowapi/skyflow-node',
  },
  {
    title: 'Python',
    image: PYTHON,
    description: 'Implementation of the Skyflow SDK for Python applications',
    link: 'https://github.com/skyflowapi/skyflow-python',
  },
  {
    title: 'Go Lang',
    image: GOLANG,
    description: 'Implementation of the Skyflow SDK for Go Lang applications',
    link: 'https://github.com/skyflowapi/skyflow-go',
  },
];
