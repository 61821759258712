import React from 'react';
import { SDKItems } from '../utils/SDKs';
import ReferencePage from '../components/common/ReferencePage';

const SDKPage: React.FC = () => {
  return (
    <ReferencePage
      title="SDKs"
      description="Skyflow offers a number of client and server side SDKs for developer convenience."
      caption="Click on an SDK to view it on Github."
      items={SDKItems}
      isRouteCard={true}
    ></ReferencePage>
  );
};

export default SDKPage;
